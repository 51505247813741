import React, {useEffect, useRef, useState} from "react";
import IconEngine from "../images/i-engine.png";
import IconRun from "../images/i-run.png";
import Prev from "../images/i-prev.png";
import Next from "../images/i-next.png";
import {GatsbyImage} from "gatsby-plugin-image";
import {CustomLink} from "./custom-link.component";


/**
 *
 * @param {PrismicPageDataBodyVehiclesItem} vehicle
 * @param {number} index
 * @param lang
 * @return {JSX.Element}
 * @constructor
 */
const CarItem = ({vehicle, index, lang}) => {
  const {engine, image, mileage, name, price, year, link} = vehicle;
  return (
    <div className={`carousel-item ${index === 0 ? "active" : ""}`}>
      <div className="mb-4">
        <div className="heading-60 text-primary fw-light">{name}</div>
        <div className="font-size-25">{year}</div>
      </div>
      <div className="position-relative mb-4">
        {link ? (
          <CustomLink link={link}>
            <GatsbyImage className="absolute-center" alt={image.alt} image={image.gatsbyImageData} />
          </CustomLink>
        ) : (
          <GatsbyImage className="absolute-center" alt={image.alt} image={image.gatsbyImageData} />
        )}
        <div className="background-car" />
      </div>
      <div className="block-information-car">
        <div>
          <div className="d-flex">
            <img className="icon me-3" src={IconEngine} alt="img" />
            <div className="">{engine}</div>
          </div>
          <div className="d-flex">
            <img className="icon me-3" src={IconRun} alt="img" />
            <div className="">{mileage}</div>
          </div>
        </div>
        <div className="ms-auto my-auto">
          {link ? (
            <CustomLink className="btn btn-primary btn-lg me-n2" link={link}>{price}</CustomLink>
          ) : (
            <div className="font-size-35">{price}</div>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 *
 * @param {PrismicPageDataBodyVehicles} slice
 * @param lang
 * @return {JSX.Element}
 * @constructor
 */
export const CarsCarousel = ({slice, lang}) => {
  const {primary, items: vehicles} = slice;
  const [carouselStep, setCarouselStep] = useState(1);
  const timerId = useRef(null);

  function onCarouselScroll() {
    timerId.current = setTimeout(() => {
      const myCarousel = document.getElementById("carouselExampleIndicators");
      const current = myCarousel.querySelector(".carousel-item.active");
      const items = Array.from(myCarousel.querySelectorAll(".carousel-item"));
      setCarouselStep(items.indexOf(current) + 1);
    }, 1000);

  }

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const myCarousel = document.getElementById("carouselExampleIndicators");
      myCarousel.addEventListener("slide.bs.carousel", onCarouselScroll, false);
      return () => {
        clearTimeout(timerId.current);
        myCarousel.removeEventListener("slide.bs.carousel", onCarouselScroll);
      };
    }
  }, [slice.items]);
  return (
    <section className="container carousel-wrapper mt-3 mt-md-5">
      {primary && primary.title ? (
        <div className="text-center">
          <div className="parallelogram gradient-dark mb-4">
            <h3 className="heading text-center px-4 py-3">{primary.title.text}</h3>
          </div>
        </div>
      ) : null}
      {vehicles && vehicles.length > 0 ? (
        <div id="carouselExampleIndicators" className="carousel slide" data-bs-interval="2000" data-bs-ride="carousel">
          <div className="carousel-inner text-center">
            {
              vehicles.map((vehicle, index) => {
                return <CarItem key={index} index={index} vehicle={vehicle} lang={lang} />;
              })
            }
            <div className="carousel-pagination">
              <span className="active">{carouselStep}</span>
              / {vehicles.length}
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="prev">
            <img className="absolute-center" src={Prev} alt="..." />
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="next">
            <img className="absolute-center" src={Next} alt="..." />
          </button>
        </div>
      ) : null}
    </section>
  );
};

import React from "react";
import GatsbyBackgroundImage from 'gatsby-background-image'
import {CustomLink} from "./custom-link.component";

/**
 *
 * @param {PrismicPageDataBodyImageWithText} slice
 * @return {JSX.Element}
 * @constructor
 */
export const BackgroundImageContentRight = ({slice}) => {
    const {title, text, button_link, button_text, image} = slice.primary;
    return (
        <section>
            <div className="background-section overflow-hidden padding-wrapper">
                <div className="container">
                    <div className="row mx-n4">
                        <div className="col-12 col-lg-6 offset-0 offset-md-6">
                            <div className="text-center text-md-start">
                                <div className="mb-4">
                                    <h3 className="heading-50 text-light fw-light mb-2">{title.text}</h3>
                                    <h3 className="text-secondary fw-light">{text.text}</h3>
                                </div>
                                <CustomLink link={button_link} className="btn btn-lg btn-info d-block d-sm-inline-block">
                                    {button_text}
                                </CustomLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

import React from 'react';
import {Banner} from "./banner.component";
import {CarsCarousel} from "./cars-carousel.component";
import {Testimonials} from "./testimonials.component";
import {ColumnsFour} from "./columns-four.component";
import {BackgroundImageContentRight} from "./background-image-content-right.component";
import {FormImageRight} from "./form-image-right.component";
import {VehiclesSearch} from "./vehicles-search.component";
import {Calculator} from "./calculator.component";
import {Employees} from "./employess.component";
import {OneColComponent} from "./one-col.component";

/**
 *
 * @param {PrismicSliceType[]} slices
 * @param lang
 * @constructor
 */
export const Slices = ({slices, lang}) => {
    return (
        <>
            {slices && slices.map((slice, index) => {
                switch (slice.slice_type) {
                    case 'banner':
                        return <Banner key={index} slice={slice} />
                    case 'vehicles':
                        return <CarsCarousel key={index} slice={slice} lang={lang} />
                    case 'testimonials':
                        return <Testimonials key={index} slice={slice} />
                    case 'features': {
                        switch(slice.slice_label) {
                            case 'one-col':
                                return <OneColComponent key={index} slice={slice} />
                            default:
                                return <ColumnsFour key={index} slice={slice} />
                        }
                    }
                    case 'image_with_text':
                        return <BackgroundImageContentRight key={index} slice={slice}/>
                    case 'form_with_list':
                        return <FormImageRight key={index} slice={slice}/>
                    case 'vehicles_search':
                        return <VehiclesSearch key={index} lang={lang} slice={slice} />
                    case 'calculator':
                        return <Calculator key={index} slice={slice} />
                    case 'employees':
                        return <Employees key={index} slice={slice}/>
                    default:
                        return null;
                }
            })}
        </>
    )
}

import React from "react";
import logo from "../images/logo_TRT_white.png";
import IconFaceBook from "../images/i-facebook.png";
import IconTwitter from "../images/i-twitter.png";
import IconYouTube from "../images/i-youtube.png";
import IconInstagram from "../images/i-instagram.png";
import IconTikTok from "../images/i-tiktok.png";
import {CustomLink} from "./custom-link.component";
import {SocialLinks} from "./navbar.component";
import {phoneToLink} from "../utils/phone";
/**
 *
 * @param {PrismicMenu} menu
 * @param {PrismicSocialLinks} socials
 * @param contacts
 * @return {JSX.Element}
 * @constructor
 */
export const Footer = ({menu, socials, contacts}) => {
    let copyrightYear = new Date().getFullYear();
    if(copyrightYear < 2021)
      copyrightYear = 2021;
    return (
        <>
            <footer>
                <div className="bg-footer">
                    <div className="container padding-wrapper">
                        <div className="row flex-column-reverse flex-lg-row">
                            <div className="col-12 col-lg-4 mt-4 mt-lg-0">
                                <div className="d-flex">
                                    <a className="footer-brand" href="#">
                                        <img className="logo" alt="logo" src={logo}/>
                                    </a>
                                    <div className="ms-3">

                                        <div className="wrapper-icon d-none d-md-block pb-2 mb-3">
                                            <SocialLinks social={socials} className="me-3 me-md-2" />
                                        </div>
                                        <small className="text-light">
                                            All rights reserved
                                            TRT International, LTD, 250 Port Street, Newark, NJ 07114 USA
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-8">
                                <div className="row">
                                    {menu.data ? menu.data.menu_items.map((menuItem, index) => {
                                        return (
                                            <div key={index} className="col-12 col-md-4">
                                                <div className="parallelogram gradient-blue h-auto w-100 py-2 mb-3">
                                                    <CustomLink link={menuItem.link}>
                                                        <div className="content text-center">
                                                            {menuItem.text}
                                                        </div>
                                                    </CustomLink>
                                                </div>
                                            </div>
                                        )
                                    }) : null}
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                            <a className="d-block text-light text-center text-md-start fw-light mb-2 mb-md-0"
                                            href={contacts.data.address_2_map_link.url} target="_blank">
                                                {contacts.data.address_2}
                                            </a>
                                    </div>
                                    <div className="col-12 col-md-4">
                                            <div className="text-center fw-light mb-2 mb-md-0">
                                                <a className="text-light" href={phoneToLink(contacts.data.phone_1)}>
                                                  {contacts.data.phone_1}
                                                </a>
                                                <br/>
                                                <a className="text-light" href={phoneToLink(contacts.data.phone_2)}>
                                                  {contacts.data.phone_2}
                                                </a>
                                            </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                            <div className="text-light text-center fw-light mb-2 mb-md-0">
                                                <a className="text-light" href={phoneToLink(contacts.data.phone_3)}>
                                                  {contacts.data.phone_3}
                                                </a>
                                                <br/>
                                                <a className="text-light" href={`mailto:${contacts.data.email}`}>
                                                  {contacts.data.email}
                                                </a>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wrapper-icon text-center d-block d-md-none">
                            <a className="me-2" href="#">
                                <img className="icon" alt="icon" src={IconFaceBook}/>
                            </a>
                            <a className="me-2" href="#">
                                <img className="icon" alt="icon" src={IconTwitter}/>
                            </a>
                            <a className="me-2" href="#">
                                <img className="icon" alt="icon" src={IconYouTube}/>
                            </a>
                            <a className="me-2" href="#">
                                <img className="icon" alt="icon" src={IconInstagram}/>
                            </a>
                            <a className="me-2" href="#">
                                <img className="icon" alt="icon" src={IconTikTok}/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="bg-dark text-center py-3">
                    <div className="text-light">
                        Copyright {copyrightYear}
                    </div>
                </div>
            </footer>
        </>
    )
}

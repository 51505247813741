import React from 'react';
import {GatsbyImage} from "gatsby-plugin-image";
import RichText from "prismic-reactjs/src/Component";

/**
 *
 * @param {PrismicPageDataBodyFeatures} slice
 * @return {JSX.Element}
 * @constructor
 */
export const OneColComponent = ({slice}) => {
    const {items, primary} = slice;
    return (
        <section className="container padding-wrapper">
            <h2 className="text-center text-dark fw-bold mb-md-5">{primary.title.text}</h2>
            <div className="position-relative">
                {items.map((item, index) => {
                    return (
                        <ul className={`row mt-3 mt-md-0 mb-0 mb-md-3 mb-lg-5 py-3 py-lg-5 ${index%2 ? 'flex-row-reverse' : ''}`}>
                            <li className="d-flex flex-column justify-content-center col-12 col-md-6 ps-md-6 pe-md-6">
                                <GatsbyImage className="about-img mb-3 mb-md-0" image={item.image.gatsbyImageData} alt={item.image.alt} />
                            </li>
                            <li className="d-flex flex-column col-12 col-md-6 ps-md-6 pe-md-6">
                                <div className="text-primary font-size-25 fw-bold">{item.title.text}</div>
                                <div className="my-auto">
                                    <RichText render={item.text.raw} />
                                </div>
                            </li>
                        </ul>
                    )
                })}
            </div>
        </section>
    )
}

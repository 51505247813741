import React from "react";
import IconFaceBook from "../images/i-facebook.png";
import IconTwitter from "../images/i-twitter.png";
import IconYouTube from "../images/i-youtube.png";
import IconInstagram from "../images/i-instagram.png";
import IconLinkedin from "../images/i-linkedin.png";
import IconTikTok from "../images/i-tiktok.png";
import IconPhone from "../images/i-phone-call.png";
import IconMail from "../images/i-mail.png";
import IconMap from "../images/i-map-pin.png";
import logo from "../images/logo_TRT_white.png";
import {CustomLink} from "./custom-link.component";
import {FormPopupComponent} from "./form-popup.component";
import {getLanguageByCode} from "../utils/langs";
import {phoneToLink} from "../utils/phone";

/**
 *
 * @param {string} className
 * @param {PrismicSocialLinks} social
 * @returns {JSX.Element | null}
 * @constructor
 */
export const SocialLinks = ({className, social}) => {
    return social ? social.data.links.map((link, index) => {
        let icon;
        switch (link.type) {
            case 'facebook':
                icon = IconFaceBook
                break;
            case 'twitter':
                icon = IconTwitter
                break;
            case 'youtube':
                icon = IconYouTube
                break;
            case 'instagram':
                icon = IconInstagram
                break;
            case 'linkedin':
                icon = IconLinkedin
                break;
            case 'tiktok':
                icon = IconTikTok
                break;
        }
        return (
            <CustomLink target="_blank" className={className} link={link.link} key={index}>
                <img className="icon" alt="icon" src={icon}/>
            </CustomLink>
        )
    }) : null
}

/**
 *
 * @param {PrismicMenu} menu
 * @param {PrismicSocialLinks} social
 * @param contacts
 * @param lang ISO code of currently selected language
 * @param sendOrderForm
 * @return {JSX.Element}
 * @constructor
 */
export const Navbar = ({menu, social, contacts, lang, sendOrderForm}) => {
    return (
        <>
            <section>
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-0 pb-2 px-xl-3">
                    <div className="container-fluid">
                        <div className="d-flex w-100 d-lg-none">
                            <div className="">
                                <div className="wrapper-icon text-center ps-3 ps-lg-0 d-block d-lg-none">
                                    <SocialLinks social={social} className="me-3 me-md-2"/>
                                </div>
                            </div>
                            <div className="ms-auto">
                                <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation">
                                    <span className="toggler-icon"/>
                                </button>
                            </div>
                        </div>

                        <div className="collapse navbar-collapse d-none d-lg-block" id="navbarNavAltMarkup">
                            <div className="navbar-nav">
                                <SocialLinks social={social} className="nav-link mx-0"/>
                            </div>
                        </div>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <div className="navbar-nav mx-2 d-block d-lg-none">
                                {menu.data.menu_items.map((menuItem, index) => {
                                    return <CustomLink key={menuItem.text + index} className="nav-link" activeClassName="active"
                                                       link={menuItem.link}>{menuItem.text}</CustomLink>
                                })}
                            </div>
                            <div className="navbar-nav mb-3 mb-lg-0 mx-2 ms-lg-auto">
                                <a className="nav-link parallelogram gradient-dark" href={phoneToLink(contacts.data.phone_1)}>
                                    <div className="content">
                                        <img className="icon" alt="icon" src={IconPhone}/>
                                        {contacts.data.phone_1}
                                    </div>
                                </a>
                                <a className="nav-link parallelogram gradient-dark" href={`mailto:${contacts.data.email}`}>
                                    <div className="content">
                                        <img className="icon" alt="icon" src={IconMail}/>
                                        {contacts.data.email}
                                    </div>
                                </a>
                                <a className="nav-link parallelogram gradient-dark"
                                   href={contacts.data.address_1_map_link.url}>
                                    <div className="content">
                                        <img className="icon" alt="icon" src={IconMap}/>
                                      {contacts.data.address_1}
                                    </div>
                                </a>
                              <div className="nav-link dropdown btn-group btn-group-justified d-grid gap-2">
                                  <button className="btn btn-dark btn-block dropdown-toggle p-0 text-start" type="button"
                                          id="dropdownMenuLanguage" data-bs-toggle="dropdown" aria-expanded="false">
                                    {getLanguageByCode(lang)}
                                  </button>
                                <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuLanguage">
                                  <li><a className="dropdown-item" href="/">Українська</a></li>
                                  <li><a className="dropdown-item" href="/ru">Русский</a></li>
                                </ul>
                              </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </section>
            <section className="position-absolute" style={{zIndex: 10}}>
                <CustomLink link="/" className="pt-4 trapezoid">
                    <img className="nav-logo" alt="logo" src={logo}/>
                </CustomLink>
                <div className="triangle"/>
            </section>
            <section className="section-main-menu d-none d-lg-block">
                <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-3 py-md-0 px-4">
                    <div className="container-fluid">
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <div className="navbar-nav navbar-menu ms-auto pe-4">
                                {menu.data.menu_items.map((menuItem, index) => {
                                    return <CustomLink key={menuItem.text + index} className="nav-link" activeClassName="active"
                                                       link={menuItem.link}>{menuItem.text}</CustomLink>
                                })}
                            </div>
                        </div>
                    </div>
                </nav>
            </section>
            <FormPopupComponent sendOrderForm={sendOrderForm}/>
        </>
    )
}

import * as React from "react";
import {withPrismicPreview} from "gatsby-plugin-prismic-previews";
import {Navbar} from "../components/navbar.component";
import {Footer} from "../components/footer.component";
import {graphql} from "gatsby";
import {Slices} from "../components/slices.component";

/**
 *
 * @param {PrismicMenu} mainMenu
 * @param {PrismicMenu} footerMenu
 * @param {PrismicPage} prismicPage
 * @param {PrismicSocialLinks} prismicSocialLinks
 *
 * @param sendOrderForm
 * @param contacts
 * @return {JSX.Element}
 * @constructor
 */
const Page = ({
                  data: {
                    mainMenu,
                    footerMenu,
                    prismicPage,
                    prismicSocialLinks,
                    sendOrderForm,
                    contacts},
              }) => {
    return (
        <>
            <Navbar menu={mainMenu}
                    social={prismicSocialLinks}
                    lang={prismicPage.lang}
                    sendOrderForm={sendOrderForm}
                    contacts={contacts}/>
            <Slices slices={prismicPage?.data?.body} lang={prismicPage.lang}/>
            <Footer menu={footerMenu} socials={prismicSocialLinks} contacts={contacts}/>
        </>
    );
};

export default withPrismicPreview(Page);

export const query = graphql`
    query Page($id: String!, $lang: String!) {
        prismicPage(id: { eq: $id }, lang: { eq: $lang }) {
            _previewable
            ...page
        }
        mainMenu: prismicMenu(uid: { eq: "main-menu" }, lang: { eq: $lang }) {
            ...menu
        }
        footerMenu: prismicMenu(uid: { eq: "footer-menu" }, lang: { eq: $lang }) {
            ...menu
        }
        sendOrderForm: prismicSendOrderForm(lang: {eq: $lang}){
            ...sendOrderForm
        }
        contacts: prismicContacts{
            ...contacts
        }
        prismicSocialLinks(type: { eq: "social_links" }) {
            ...socials
        }
    }
`;

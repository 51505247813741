import React from "react"
import {Link} from "gatsby"
import {linkResolver} from "../utils/linkResolver";

/**
 *
 * @param {JSX.Element|null} children
 * @param {PrismicLinkType} link
 * @param {string} className
 * @param {string} activeClassName
 * @param {*} style
 * @param {string} target
 * @return {JSX.Element|null}
 * @constructor
 */
export const CustomLink = ({children, link, className, activeClassName, style, target}) => {
    if (link && typeof link === 'object') {
        switch (link.link_type) {
            case "Document":
                if (link.document) {
                    return (
                        <Link
                            to={linkResolver(link.document)}
                            className={className}
                            partiallyActive={link.document.uid !== "home"}
                            activeClassName={activeClassName}
                            style={style}
                            target={target}
                        >
                            {children}
                        </Link>
                    )
                }
                return (
                    <span className={className} style={style}>
            {children}
          </span>
                )
            case "Web":
                let {url} = link
                if (url) {
                    url = url.replace(/^https?:\/\/#/, "#")
                }
                return (
                    <a href={url} target={target} style={style} className={className}>
                        {children}
                    </a>
                )
            default:
                return children ? (<button style={style} className={className}>{children}</button>) : null
        }
    } else if (link && typeof link === 'string') {
        if (/^https?:\/\/#/.test(link)) {
            return (
                <a href={link} target={target} style={style} className={className}>
                    {children}
                </a>
            )
        }
        return <Link to={link} className={className}
                     activeClassName={activeClassName}
                     style={style}
                     target={target}>
            {children}
        </Link>
    }
    return children || null
}

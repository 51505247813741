import React from "react";

export const HeaderWithLineComponent = ({text}) => {
    return (
        <div className="position-relative text-center">
            <div className="border border-dark absolute-y-center l-0 r-0 "/>
            <div className="header-dark-light">
                <h3 className="text-center max-width-700 fw-light text-dark mx-auto text-uppercase">
                    {text}
                </h3>
            </div>
        </div>
    )
}
import React from "react";
import {RichText} from "prismic-reactjs";
import {GatsbyImage} from "gatsby-plugin-image";
import {CustomLink} from "./custom-link.component";

/**
 *
 * @param {PrismicPageDataBodyBanner | PrismicSliceType} slice
 * @return {JSX.Element}
 * @constructor
 */
export const Banner = ({slice}) => {
  const hasImage = slice.primary.bg_image && slice.primary.bg_image.url;
  return (
    <section className={`section-banner ${hasImage ? "banner-with-image" : ""} position-relative`}>
      {hasImage ? (
        <GatsbyImage className="w-100" alt={slice.primary.bg_image.alt} image={slice.primary.bg_image.gatsbyImageData} />
      ) : null}
      <div className={hasImage ? "banner-content" : "background-gray"}>
        <div className={hasImage ? "banner-content-align" : "container"}>
          <div className="main-heading">
            <RichText render={slice.primary.title.raw} />
            {slice.primary.text ? (
              <RichText render={slice.primary.text.raw} />
            ) : null}
          </div>
          <CustomLink link={slice.primary.button_link} className="btn btn-info btn-lg opacity-90">
            {slice.primary.button_text}
          </CustomLink>
        </div>
      </div>
    </section>
  );
};

import React, {useEffect, useRef} from "react";
import IconPlay from "../images/i-play.png";
import {GatsbyImage} from "gatsby-plugin-image";
import UserPhoto from "../images/user.png";
import {CustomLink} from "./custom-link.component";


/**
 * @param {number} index
 * @param {PrismicPageDataBodyTestimonialsItem} testimonial
 * @constructor
 */
export const VideoTestimonial = ({testimonial, index}) => {
  const id = `testimonial-${index}`;
  const video = useRef(null);
  let videoModal = useRef(null);
  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const {Modal} = require("bootstrap");
      const modalEl = document.getElementById(id);
      if (modalEl) {
        videoModal.current = new Modal(modalEl);
        modalEl.addEventListener("hidden.bs.modal", function() {
          if (video.current) {
            video.current.pause();
          }
        });
        modalEl.addEventListener("shown.bs.modal", function() {
          if (video.current) {
            video.current.play();
          }
        });
      }
    }
  }, []);

  const openModal = () => {
    if (videoModal.current) {
      videoModal.current.show();
    }
  };

  const closeModal = () => {
    if (videoModal.current) {
      videoModal.current.hide();
    }
  };

  return (
    <div className="video-testimonial col-12 col-md-4 mb-3 mb-lg-0">
      <div className="position-relative">
        <img className="icon-play absolute-center" alt="play-btn" src={IconPlay} onClick={openModal} />
        <div className="cover" />
        <GatsbyImage className="video-testimonial-thumbnail w-100" alt={testimonial.video_thumbnail.alt}
                     image={testimonial.video_thumbnail.gatsbyImageData} />
      </div>
      <div className="modal fade" id={id} tabIndex="-1" aria-labelledby="exampleModalLabel"
           aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" onClick={closeModal}
                      aria-label="Close" />
            </div>
            <div className="modal-body">
              <video width="100%" src={testimonial.video_url} controls ref={video} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 *
 * @param {PrismicPageDataBodyTestimonials} slice
 * @return {JSX.Element}
 * @constructor
 */
export const Testimonials = ({slice}) => {
  const {primary, items: testimonials} = slice;

  switch (slice.slice_label) {
    case "video-testimonials":
      return (
        <section className="container padding-wrapper">
          <div className="row mb-3">
            {testimonials.map((testimonial, index) => {
              return <VideoTestimonial key={index} index={index} testimonial={testimonial} />;
            })}
          </div>
          {primary.link_text ? (
            <div className="text-end">
              <CustomLink link={primary.link}>Посмотреть все</CustomLink>
            </div>
          ) : null}
        </section>
      );
    case "text-testimonials":
      return (
        <section className="container padding-wrapper">
          <ul className="row">
            {testimonials.map((testimonial, index) => {
              return (
                <li className="col-12 col-md-6 col-lg-4 mb-5">
                  <div className="d-flex mb-2">
                    {testimonial.client_photo && testimonial.client_photo.gatsbyImageData ? (
                      <GatsbyImage className="user-photo" alt={testimonial.client_name}
                                   image={testimonial.client_photo.gatsbyImageData} />
                    ) : (
                      <img className="user-photo" src={UserPhoto} />
                    )}
                    <div className="ms-3">
                      <div className="text-primary mb-1">{testimonial.client_name}</div>
                      <div className="d-flex rates-wrapper">
                        {
                          new Array(5).fill(1).map((i, index) => {
                            return (<div className={`rating ${index < testimonial.rating ? "active" : ""}`} />);
                          })
                        }
                      </div>
                    </div>

                  </div>
                  <em>
                    {testimonial.text.text}
                  </em>
                </li>
              );
            })}
          </ul>
          {primary.link_text ? (
            <div className="text-end">
              <CustomLink link={primary.link}>Посмотреть все</CustomLink>
            </div>
          ) : null}
        </section>
      );
    default:
      return null;
  }

};

const {DEFAULT_LANG} = require("../../properties");

/**
 *
 * @param {PrismicAllDocumentTypes} doc
 * @return {string}
 */
module.exports.linkResolver = (doc) => {
    console.log(doc);
    const lang = doc.lang && doc.lang !== DEFAULT_LANG ? `/${doc.lang}` : ""
    if (!doc.lang) {
        doc.lang = DEFAULT_LANG
    }
    switch (doc.type) {
        case "vehicle":
            return `${lang}/vehicles/${doc.uid}`
        case "page":
            if(doc.uid === 'home') {
                return `${lang}/`
            }
            return `${lang}/${doc.uid}`
        case "blog_category":
            return `${lang}/blog/${doc.uid}`
        default:
            return "/404"
    }
}

import React, {useState} from "react";
import ImgTablet from "../images/img-tablet.png";
import RichText from "prismic-reactjs/src/Component";
import {GatsbyImage} from "gatsby-plugin-image";
import {Form, Formik, Field} from "formik";
import axios from "axios";

/**
 *
 * @param {PrismicPageDataBodyFormWithListItem} item
 * @constructor
 */
export const FormWithListItem = ({item}) => {
    const {text, image, title} = item;
    return (
        <li className="d-flex flex-row">
            {image ? (<div className="d-flex flex-column justify-content-center">
                <div className="round position-relative me-3">
                    <GatsbyImage className="absolute-center" image={image.gatsbyImageData} alt={image.alt}/>
                </div>
            </div>) : null}
            <div className="d-flex flex-column justify-content-center break">
                {title ? (
                    <h4 className="header-tablet text-primary mb-0">{title.text}</h4>
                ) : null}
                {text ? (
                    <div className="cpmb-0">
                        <RichText render={text.raw} />
                    </div>
                ) : null}
            </div>
        </li>
    )
}


/**
 * @param {PrismicPageDataBodyFormWithList} slice
 * @return {JSX.Element}
 * @constructor
 */
export const FormImageRight = ({slice}) => {
    const {primary, items} = slice;
    const [message, setMessage] = useState("");
  const onSubmitForm = (values, {resetForm}) => {
    let formdata = new FormData();
    const {name, phone} = values;

    formdata.append("xnQsjsdp", '6f8c918b43242bcb249bc03734f72778fb6c0e4d3f3661112d7486507377418c')
    formdata.append("zc_gad", "")
    formdata.append("xmIwtLD", "76b275ad5c6d0cee23144c3c284090f37734dc9c3211a92a9243bff2a415057f")
    formdata.append("actionType", "TGVhZHM=")
    formdata.append("returnURL", "https://ua.usatrt.com");
    formdata.append("Last Name", name);
    formdata.append("Phone", phone);
    formdata.append("Website", "https://ua.usatrt.com");

    axios({
      method: "post",
      url: "https://crm.zoho.com/crm/WebToLeadForm",
      data: formdata,
      headers: {"Content-Type": "application/x-www-form-urlencoded"},
    })
      .then(function (response) {
        setMessage("Форма успешно отправлена, наши специалисты с вами свяжутся");
        resetForm();
      })
      .catch(function (response) {
        setMessage("Ошибка, повторите попытку позже");
      });
  }
    return (
        <section className="position-relative">
            <div className="gradient-blue gradient-blue-bottom ">
            </div>
            <div className="container padding-wrapper">
                <div className="row">
                    <div className="col-12 col-md-7 mb-3 my-md-auto">
                        <div className="mb-3 mb-md-5 text-center text-md-start">
                            {primary.title ? (
                                <h3 className="heading-60 fw-light mb-2">
                                    {primary.title.text}
                                </h3>
                            ) : null}
                            {primary.text ? (
                                <RichText render={primary.text.raw}/>
                            ) : null}
                        </div>
                      <Formik
                        initialValues={{name: '', phone: ''}}
                        onSubmit={onSubmitForm}
                      >
                        <Form action="none">
                            <div className="row mb-3">
                                <div className="col-12 col-md-6 mb-3 mb-md-0">
                                    <Field required className="form-control" name="name" placeholder="ИМЯ" type="text"/>
                                </div>
                                <div className="col-12 col-md-6 mb-3 mb-md-0">
                                    <Field required className="form-control" name="phone" placeholder="ТЕЛЕФОН" type="tel"/>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-info btn-lg w-100">ОТПРАВИТЬ</button>
                            <div className="text-center">
                              {message}
                            </div>
                        </Form>
                      </Formik>
                    </div>
                    <div className="col-12 col-md-5">
                        <div className="position-relative">
                            <img alt="tablet image" width="100%" src={ImgTablet}/>
                            <div className="tablet-content">
                              <ul className="d-flex flex-column justify-content-around p-3 h-100 mb-0">
                                {items.map((item, index) => {
                                  return <FormWithListItem item={item} key={index} />
                                })}
                              </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

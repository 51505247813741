import React, {useEffect, useRef} from "react";
import {Form, Formik, Field} from "formik";
import axios from "axios";

export const FormPopupComponent = (sendOrderForm) => {
  const labels = sendOrderForm.sendOrderForm.data;
  const closeBtn = useRef(null);
  const openPopup = () => {
    if (window.location.hash === "#form-popup") {
      window.history.replaceState(null, "form", "#");
      const bootstrap = require("bootstrap");
      const modal = new bootstrap.Modal(document.getElementById("form-popup"), {});
      modal.show();
    } else if (window.location.hash === "") {
      if (closeBtn.current) {
        closeBtn.current.click();
      }
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      window.addEventListener("hashchange", openPopup, false);
      return () => {
        window.removeEventListener("hashchange", openPopup);
      };
    }
  }, []);
  const onSubmitForm = (values, {setSubmitting}) => {
    let formdata = new FormData();
    const {name, phone, selected, budget} = values;

    formdata.append("xnQsjsdp", "6f8c918b43242bcb249bc03734f72778fb6c0e4d3f3661112d7486507377418c");
    formdata.append("zc_gad", "");
    formdata.append("xmIwtLD", "76b275ad5c6d0cee23144c3c284090f37734dc9c3211a92a9243bff2a415057f");
    formdata.append("actionType", "TGVhZHM=");
    formdata.append("returnURL", "https://ua.usatrt.com");
    formdata.append("Last Name", name);
    formdata.append("Phone", phone);
    formdata.append("Description", `Уже выбрали авто? ${selected}, Ваш бюджет? ${budget}`);
    formdata.append("Website", "https://ua.usatrt.com");

    axios({
      method: "post",
      url: "https://crm.zoho.com/crm/WebToLeadForm",
      data: formdata,
      headers: {"Content-Type": "application/x-www-form-urlencoded"},
    })
      .then(function(response) {
        if (closeBtn.current) {
          closeBtn.current.click();
        }
        const bootstrap = require("bootstrap");
        const modal = new bootstrap.Modal(document.getElementById("form-success-popup"), {});
        modal.show();
      })
      .catch(function(response) {

      });
  };

  return (
    <>
      <div className="modal fade" id="form-popup" tabIndex="-1" aria-labelledby="form-popup"
           aria-hidden="true">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">{labels.send_order}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <Formik
                initialValues={{name: "", comment: "", phone: ""}}
                onSubmit={onSubmitForm}
              >
                <Form action="none">
                  <div className="form-group">
                    <label className="form-label" htmlFor="popupFormName">
                      {labels.name}
                    </label>
                    <Field className="form-control" required type="text" name="name" id="popupFormName" />
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="popupFormPhone">
                      {labels.phone}
                    </label>
                    <Field className="form-control" required type="tel" name="phone" id="popupFormPhone" />
                  </div>
                  <fieldset className="mt-2">
                    <legend className="font-size-16">
                      {labels.your_budget}
                    </legend>
                    <div className="form-group">
                      <label className="d-block">
                        <Field type="radio" name="budget" value="от $6000-9000" />
                        &nbsp; {labels.from} $6000-9000
                      </label>
                      <label className="d-block">
                        <Field type="radio" name="budget" value="от $9000-12000" />
                        &nbsp; {labels.from} $9000-12000
                      </label>
                      <label className="d-block">
                        <Field type="radio" name="budget" value="от $9000-12000" />
                        &nbsp; {labels.from} 12000 {labels.and_more}
                      </label>
                    </div>
                  </fieldset>
                  <fieldset className="mt-2">
                    <legend className="font-size-16">
                      {labels.did_you_choose_car}
                    </legend>
                    <div className="form-group">
                      <label className="d-block">
                        <Field type="radio" name="selected" value={labels.did_you_choose_car_yes} />
                        &nbsp; {labels.did_you_choose_car_yes}
                      </label>
                      <label className="d-block">
                        <Field type="radio" name="selected" value={labels.did_you_choose_car_no} />
                        &nbsp; {labels.did_you_choose_car_no}
                      </label>
                    </div>
                  </fieldset>
                  <div className="form-group mt-5">
                    <div className="row">
                      <div className="col-6">
                        <button type="submit"
                                className="btn btn-primary d-block w-100">{labels.send}
                        </button>
                      </div>
                      <div className="col-6">
                        <button data-bs-dismiss="modal" type="reset" ref={closeBtn}
                                className="btn btn-default d-block w-100">{labels.cancel}
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="form-success-popup" tabIndex="-1" aria-labelledby="form-popup"
           aria-hidden="true">
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">{labels.thanks_for_your_order}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              {labels.our_specialists_will_contact_you}
            </div>
            <div className="modal-footer">
              <button data-bs-dismiss="modal"
                      className="btn btn-default d-block w-100">{labels.ok}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

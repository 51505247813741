import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import RichText from "prismic-reactjs/src/Component";
import {HeaderWithLineComponent} from "./header-with-line.component";

/**
 *
 * @param {PrismicPageDataBodyFeaturesItem} item
 * @constructor
 */
export const ColFour = ({item}) => {
    return (
        <div className="col-12 col-md-6 col-lg-3">
            <div className="col-4-img h-100 mx-auto p-3 p-lg-5">
                {item.image ? <GatsbyImage alt={item.image.alt} image={item.image.gatsbyImageData} />: null}
                {item.title ? <RichText render={item.title.raw}/> : null}
                {item.text ? <RichText render={item.text.raw}/> : null}
            </div>
        </div>
    )
}

/**
 * @param {PrismicPageDataBodyFeatures} slice
 * @return {JSX.Element}
 * @constructor
 */
export const ColumnsFour = ({slice}) => {
    const {items, primary} = slice;
    return (
        <section className="container padding-wrapper">
            {primary.title ? (
                <HeaderWithLineComponent text={primary.title.text}/>
            ) : null}
            <div className="row g-3 mt-3 mt-md-5">
                {items.map((item, index) => {
                    return  <ColFour key={index} item={item}/>
                })}
            </div>
        </section>
    )
}

import "./src/styles/main.scss";
import "bootstrap";
import * as React from 'react'
import {
    PrismicPreviewProvider,
    componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'
import { PrismicProvider } from '@prismicio/react'

import {linkResolver} from './src/utils/linkResolver'
import Page from './src/templates/page'
import {Link} from "gatsby";

export const wrapRootElement = ({element}) => (
    <PrismicProvider
        internalLinkComponent={({href, ...props}) => (
            <Link to={href} {...props} />
        )}
    >
        <PrismicPreviewProvider
            repositoryConfigs={[
                {
                    repositoryName: 'usatrt-ua',
                    linkResolver,
                    componentResolver: componentResolverFromMap({
                        page: Page,
                    }),
                },
            ]}
        >
            {element}
        </PrismicPreviewProvider>
    </PrismicProvider>
)


import React, {useEffect, useRef, useState} from "react";
import GrayLogoCopart from "../images/gray-logo-copart.png";
import GrayLogoAA from "../images/gray-logo-aa.png";
import GrayLogoManheim from "../images/gray-logo-manheim.png";
import axios from "axios";
import {API_ENDPOINT, DESTINATION_PORT} from "../../properties";
import {Typeahead} from "react-bootstrap-typeahead";
import {ImCross} from "react-icons/all";

/**
 * Calculator component
 * @param {PrismicPageDataBodyCalculator} slice
 * @return {JSX.Element | null}
 * @constructor
 */
export const Calculator = ({slice}) => {
    const destinationId = DESTINATION_PORT;
    const calculatorAuctionsRef = useRef(null);
    const {
        fedex_delivery_price = 0,
        brokerage_and_forwarding_services = 0,
        diesel_coefficient = 0,
        petrol_coefficient = 0,
        copart_coefficient = 0,
        iaai_coefficient = 0,
        manheim_coefficient = 0
    } = slice.primary;

    const [year, setYear] = useState(2021);
    const [price, setPrice] = useState(15000);
    const [volume, setVolume] = useState(3);
    const [auctions, setAuctions] = useState([]);
    const [auction, setAuction] = useState("copart")
    const [shippingError, setSippingError] = useState("");
    const [inlandOceanQuote, setInlandOceanQuote] = useState(null);
    const [originId, setOriginId] = useState(null);
    const [engine, setEngine] = useState('petrol');

    const getAuctions = () => {
        axios.get(`${API_ENDPOINT}/api/geo/auctionlocations`,
            {
                headers: {
                    Accept: "application/json",
                },
            }).then(result => {
            setAuctions(result.data);
        })
    }

    const auctionChanged = ([auction]) => {
        setOriginId(auction ? auction.Id : null);
        setSippingError("");
    }

    const submitShippingForm = async (event) => {
        event.preventDefault();
        if (!originId) {
            setSippingError(slice.primary.choose_auction_error)
        } else {
            const {data: quoteInlandOcean} = await axios.get(
                `${API_ENDPOINT}/api/calculator/quoteinlandocean?auctionlocationId=${originId}&destinationId=${destinationId}`,
                {
                    headers: {
                        Accept: "application/json",
                    },
                },
            );
            if (!quoteInlandOcean.rate) {
                setSippingError(slice.primary.tariff_not_found_error);
                setInlandOceanQuote(null);
            } else {
              setInlandOceanQuote(quoteInlandOcean);
            }
        }
    }

    const getFuelCoefficient = () => {
        if (engine === 'petrol') {
            return parseFloat(petrol_coefficient, 10);
        } else if (engine === 'diesel') {
            return parseFloat(diesel_coefficient, 10);
        }
        return 0;
    }

    const getTax = () => {
        const _volume = engine === 'electric' ? 0 : parseFloat(volume, 10);
        const k = getFuelCoefficient();
        return Math.round((_volume) * k * (new Date().getFullYear() - parseFloat(year, 10) + 1));
    }

    const getCustomsPayments = () => {
       return Math.round((parseFloat(price, 10) + getAuctionTax()) * 0.1);
    }

    const getNds = () => {
        return Math.round((getTax() + parseInt(price, 10) + getCustomsPayments()) * 0.2);
    }

    const getAuctionTax = () => {
        switch(auction) {
            case 'copart':
                return Math.round(parseFloat(price, 10) * parseFloat(copart_coefficient, 10));
            case 'iaai':
                return Math.round(parseFloat(price, 10) * parseFloat(iaai_coefficient, 10));
            case 'manheim':
                return Math.round(parseFloat(price, 10) * parseFloat(manheim_coefficient, 10));
            default:
                return 0;
        }
    }

    const getDeliveryTotal = () => {
        return ((inlandOceanQuote && inlandOceanQuote.rate ? inlandOceanQuote.rate : 0)
            + fedex_delivery_price
            + brokerage_and_forwarding_services)
    }

    const getTaxTotal = () => {
        const _volume = engine === 'electric' ? 0 : volume;
        const k = getFuelCoefficient();
        return Math.round(((_volume) * k) + getCustomsPayments() + getTax() + getNds());
    }

    const getTotal = () => {
        return parseInt(price, 10) + parseInt(getTaxTotal(), 10) + parseInt(getDeliveryTotal(), 10) + parseInt(price * 0.015, 10) + parseInt(getAuctionTax(), 10);
    }



    useEffect(() => {
        getAuctions();
    }, []);

    return (
        <>
            <section className="container my-5">
                <div className="pt-5">
                    <div className="border border-dark"/>
                    <div className="mt-n5 pb-3 mb-3 mb-md-4 bg-white mx-5 mx-lg-auto">
                        <h3 className="text-center max-width-700 fw-light mx-auto text-dark">
                            {slice.primary.find_out_delivery_cost}
                        </h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="shadow mb-3 p-4 h-100">
                            <form onSubmit={submitShippingForm}>
                                <h3 className="heading text-info font-size-42 pb-2">{slice.primary.delivery_calculator}</h3>
                                {shippingError ? (
                                    <div className="alert alert-danger" role="alert">
                                        {shippingError}
                                    </div>
                                ) : null}
                                <div className="mb-4">
                                    <label htmlFor="selectAuction"
                                           className="form-label font-size-25 font-weight-300 text-dark mb-2">
                                        {slice.primary.auction}
                                    </label>
                                  <div className="position-relative">
                                    <Typeahead id="selectAuction"
                                               ref={calculatorAuctionsRef}
                                               multiple={false}
                                               onChange={auctionChanged}
                                               placeholder={slice.primary.select_auction_placeholder}
                                               options={auctions}
                                               labelKey={"FullName"}
                                    />
                                    <ImCross className="clear-auction-icon" onClick={() => {
                                      calculatorAuctionsRef.current.clear();
                                      setOriginId(null);
                                      setInlandOceanQuote(null);
                                    }} />
                                  </div>
                                </div>
                                <button type="submit" className="btn w-100 btn-outline-info">
                                  {slice.primary.calculate}
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="shadow flex-grow-1 wrapper-cost h-100">
                            <div className="mb-3 font-size-20">
                            <span className="text-secondary">
                                {slice.primary.delivery_from_america}
                            </span>
                                <b className="d-block">
                                    $ {inlandOceanQuote && inlandOceanQuote.rate ? (inlandOceanQuote.rate) : '0'}
                                </b>
                            </div>
                            <div className="mb-3 font-size-20">
                            <span className="text-secondary">
                                {slice.primary.document_delivery}
                            </span>
                                <b className="d-block">
                                    $ {fedex_delivery_price || 0}
                                </b>
                            </div>
                            <div className="mb-4 font-size-20">
                            <span className="text-secondary">
                                {slice.primary.broker_services}
                            </span>
                                <b className="d-block">
                                    $ {brokerage_and_forwarding_services || 0}
                                </b>
                            </div>
                            <div className="total-cost">
                                <span className="font-size-20 d-block">
                                    {slice.primary.delivery_cost}
                                </span>
                                <b className="font-size-25 text-info">
                                    $ {getDeliveryTotal()}
                                </b>
                            </div>
                            <div className="bottom-line"/>
                        </div>
                    </div>
                </div>

                <div className="row mb-5 mt-5">
                    <div className="col-12 col-lg-6">
                        <div className="shadow mb-3 p-4 h-100">
                            <div className="row mb-4">
                                <h3 className="heading text-info font-size-42 pb-2">{slice.primary.customs_clearance_calculator}</h3>
                                <div className="font-size-25 fw-light text-dark mb-2">{slice.primary.auction}</div>
                                <div className="d-flex w-100 justify-content-between" onChange={(event) => {
                                    setAuction(event.target.value)
                                }}>
                                    <div className="auction-button mb-2 mb-md-0">
                                        <input defaultChecked={true} type="radio" value="copart" id="auction_copart"
                                               name="auction"/>
                                        <label htmlFor="auction_copart">
                                            <div className="btn w-100 btn-sm px-md-5 px-lg-3 px-xl-4">
                                                <img height="30" alt="img" src={GrayLogoCopart}/>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="auction-button mb-2 mb-md-0">
                                        <input type="radio" id="auction_aa" name="auction" value="iaai"/>
                                        <label htmlFor="auction_aa">
                                            <div className="btn w-100 btn-sm px-md-5 px-lg-3 px-xl-4">
                                                <img height="30" alt="img" src={GrayLogoAA}/>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="auction-button mb-2 mb-md-0">
                                        <input type="radio" id="auction_manheim" name="auction" value="copart"/>
                                        <label htmlFor="auction_manheim">
                                            <div className="btn w-100 btn-sm px-md-5 px-lg-3 px-xl-4">
                                                <img height="30" alt="img" src={GrayLogoManheim}/>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-12">
                                    <div className="font-size-25 fw-light text-dark mb-2">
                                      {slice.primary.engine}
                                    </div>
                                    <div onChange={(event) => setEngine(event.target.value)}
                                         className="d-flex w-100 justify-content-between">
                                        <div className="form-check">
                                            <input defaultChecked={true} value="petrol" className="form-check-input"
                                                   type="radio"
                                                   name="engine"
                                                   id="engine1"/>
                                            <label className="form-check-label" htmlFor="engine1">
                                              {slice.primary.petrol}
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input value="diesel" className="form-check-input" type="radio"
                                                   name="engine"
                                                   id="engine2"/>
                                            <label className="form-check-label" htmlFor="engine2">
                                              {slice.primary.diesel}
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input value="electric" className="form-check-input" type="radio"
                                                   name="engine"
                                                   id="engine4"/>
                                            <label className="form-check-label" htmlFor="engine4">
                                              {slice.primary.electric}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="volumeRange"
                                       className="form-label font-size-25 fw-light text-dark mb-2">
                                  {slice.primary.engine_volume}
                                </label>
                                <div className="d-inline-block float-end year-input">
                                    <input type="number" min={0} max={40} step={0.1}
                                           onChange={(event) => setVolume(event.target.value)}
                                           className="range-mark" value={volume}/>
                                </div>
                                <input type="range"
                                       value={volume}
                                       onChange={(event) => setVolume(event.target.value)} className="form-range"
                                       min="0" max="10" step="0.1"
                                       id="volumeRange"/>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="yearRange"
                                       className="form-label font-size-25 fw-light text-dark mb-2">
                                  {slice.primary.year}
                                </label>
                                <div className="d-inline-block float-end year-input">
                                    <input type="number" onChange={(event) => setYear(event.target.value)}
                                           className="range-mark" value={year}/>
                                </div>
                                <input type="range" defaultValue={2021}
                                       onChange={(event) => setYear(event.target.value)} className="form-range"
                                       min="1990" max="2021" step="1"
                                       id="yearRange"/>
                            </div>
                            <div className="">
                                <label htmlFor="priceRange"
                                       className="form-label font-size-25 fw-light text-dark mb-2">
                                  {slice.primary.cost}
                                </label>
                                <div className="d-inline-block float-end price-input">
                                    <input type="number" onChange={(event) => setPrice(event.target.value)}
                                           className="range-mark" value={price}/>
                                </div>
                                <input type="range" onChange={(event) => setPrice(event.target.value)}
                                       className="form-range" value={price} min="1000" max="100000" step="100"
                                       id="priceRange"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="shadow wrapper-cost h-100">
                            <div className="mb-3 font-size-20">
                            <span className="text-secondary">
                                {slice.primary.lot_price}
                            </span>
                                <b className="d-block">
                                    $ {price}
                                </b>
                            </div>
                            <div className="mb-3 font-size-20">
                            <span className="text-secondary">
                                {slice.primary.customs_payments}
                            </span>
                                <b className="d-block">
                                    $ {getCustomsPayments()}
                                </b>
                            </div>
                            <div className="mb-3 font-size-20">
                            <span className="text-secondary">
                                {slice.primary.auction_fee}
                            </span>
                                <b className="d-block">
                                    $ {getAuctionTax()}
                                </b>
                            </div>
                            <div className="mb-3 font-size-20">
                            <span className="text-secondary">
                                {slice.primary.excise}
                            </span>
                                <b className="d-block">
                                    $ {getTax()}
                                </b>
                            </div>
                            <div className="mb-4 font-size-20">
                            <span className="text-secondary">
                                {slice.primary.vat}
                            </span>
                                <b className="d-block">
                                    $ {getNds()}
                                </b>
                            </div>
                            <div className="total-cost">
                                <span className="font-size-20 d-block">
                                    {slice.primary.customs_clearance_cost}
                                </span>
                                <b className="font-size-25 text-info">
                                    $ {getTaxTotal()}
                                </b>
                            </div>
                            <div className="bottom-line"/>
                        </div>
                    </div>
                </div>

            </section>

            <section className="container my-5">
                <div className="shadow wrapper-cost">
                    <h3 className="heading text-info font-size-42 py-2 py-lg-3">Общая стоимость</h3>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="mb-3 font-size-20">
                            <span className="text-secondary">
                                {slice.primary.lot_price}
                            </span>
                                <b className="d-block">
                                    $ {price}
                                </b>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="mb-3 font-size-20">
                            <span className="text-secondary">
                                {slice.primary.customs_clearance_cost}
                            </span>
                                <b className="d-block">
                                    $ {getTaxTotal()}
                                </b>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="mb-3 font-size-20">
                            <span className="text-secondary">
                                {slice.primary.auction_fee}
                            </span>
                                <b className="d-block">
                                    $ {getAuctionTax()}
                                </b>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="mb-3 font-size-20">
                            <span className="text-secondary">
                                {slice.primary.delivery_cost}
                            </span>
                                <b className="d-block">
                                    $ {getDeliveryTotal()}
                                </b>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="mb-3 font-size-20">
                            <span className="text-secondary">
                                {slice.primary.car_insurance} <small>{slice.primary.car_insurance_percentage}</small>
                            </span>
                                <b className="d-block">
                                    $ {price * 0.015}
                                </b>
                            </div>
                        </div>
                      <div className="col-12 col-lg-6">
                        <div className="mb-3 font-size-20">
                            <span className="text-secondary">
                                {slice.primary.customs_payments}
                            </span>
                          <b className="d-block">
                            $ {getCustomsPayments()}
                          </b>
                        </div>
                      </div>
                    </div>
                    <div className="total-cost">
                        <div className="row">
                            <div className="col-12 col-lg-6 mb-2 mb-lg-0">
                            <span className="d-block font-size-20">
                            {slice.primary.total_delivery_price}
                            </span>
                                <b className="text-info mb-0 font-size-25">
                                    $ {getTotal()}
                                </b>
                            </div>
                            <div className="col-12 col-lg-6">
                                <a href="#form-popup" type="submit" className="btn btn-lg w-100 btn-info mt-2">
                                  {slice.primary.order}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-line"/>
                </div>
            </section>
        </>
    )
}

import React, {useEffect, useRef, useState} from "react";
import IconDown from "../images/i-down.png";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";
import {graphql, Link, StaticQuery} from "gatsby";
import StickyBox from "react-sticky-box";
import {Pagination} from "./pagination.component";
import {getLangPrefix} from "../utils/langs";

/**
 *
 * @param {PrismicVehicle} vehicle
 * @param {string} uid
 * @param {PrismicMake[]} makes
 * @param {PrismicModel[]} models
 * @param lang
 * @param labels
 * @constructor
 */
export const VehicleItem = ({
                              uid,
                              vehicle,
                              makes,
                              models,
                              lang,
                              labels
}) => {
  let {
    pictures,
    year,
    engine,
    millage,
    make,
    model,
    transmission,
    price,
  } = vehicle;
  if (makes) {
    make = makes.find(m => m.prismicId === make.value.document.id)?.data?.name;
  } else {
    make = make?.document?.data?.name;
  }
  if (models) {
    model = models.find(m => m.prismicId === model.value.document.id)?.data?.name;
  } else {
    model = model?.document?.data?.name;
  }
  const image = (pictures?.value ? pictures?.value : pictures)?.sort((a, b) => {
    if (a.is_featured && !b.is_featured) {
      return 1;
    } else if (!a.is_featured && b.is_featured) {
      return -1;
    } else return 0;
  })[0];
  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-5 mb-3 mb-lg-0">
          <Link to={`${getLangPrefix(lang)}/vehicles/${uid}`}>
            {image?.vehicle_picture?.value?.main?.url ? (
              <img
                className="vehicle-list-image w-100"
                src={image?.vehicle_picture?.value?.main?.url}
                alt={image?.vehicle_picture?.value?.main?.alt}
              />
            ) : (
              image?.vehicle_picture?.gatsbyImageData ?
                (
                  <GatsbyImage
                    image={image?.vehicle_picture?.gatsbyImageData}
                    className="vehicle-list-image w-100"
                    alt={image?.vehicle_picture?.alt} />
                ) : (
                  <StaticImage
                    className="vehicle-list-image w-100"
                    src="../images/no-car-image.jpeg"
                    alt="No car image"
                  />
                )
            )}
          </Link>
        </div>
        <div className="col-12 col-lg-7 d-flex flex-column justify-content-between">
          <div className="d-flex">
            <Link to={`${getLangPrefix(lang)}/vehicles/${uid}`}>
              <h3 className="mb-3">
                {make?.value !== undefined ? make?.value : make}&nbsp;
                {model?.value !== undefined ? model?.value : model}&nbsp;
                {year?.value !== undefined ? year?.value : year}
              </h3>
            </Link>
            <div className="ms-auto text-end font-weight-500">
              <div className="font-size-25 text-dark">
                $&nbsp;{price?.value !== undefined ? price?.value : price}
              </div>
            </div>
          </div>
          <ul className="flex-grow-1 text-secondary font-weight-500 line-height-30 mb-4">
            <li>
              {labels.label_mileage}: {millage?.value !== undefined ? millage?.value : millage}
            </li>
            <li>
              {labels.label_engine}: {engine?.value !== undefined ? engine?.value : engine}
            </li>
            <li>
              {labels.label_transmission}:{" "}
              {transmission?.value !== undefined
                ? transmission?.value
                : transmission}
            </li>
          </ul>
          <div className="row">
            <div className="col-12 col-lg-6">
              <a
                href="#form-popup"
                className="btn btn-md btn-info w-100 mb-2 mb-lg-0"
              >
                {labels.label_order}
              </a>
            </div>
            <div className="col-12 col-lg-6">
              <Link
                to={`${getLangPrefix(lang)}/vehicles/${uid}`}
                className="btn btn-md btn-outline-info w-100"
              >
                {labels.label_find_out_more}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <hr className="w-100 my-5" />
    </>
  );
};

const query = graphql`
  query vehiclesFiltersQuery {
    allPrismicVehicle {
      edges {
        node {
          uid
          data {
            vin
            year
            discount
            engine
            millage
            pictures {
              is_featured
              vehicle_picture {
                alt
                url
                gatsbyImageData
              }
            }
            price
            transmission
            type
            make {
              document {
                ... on PrismicMake {
                  data {
                    name
                  }
                }
              }
            }
            model {
              document {
                ... on PrismicModel {
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
      types: distinct(field: data___type)
      engines: distinct(field: data___engine)
      transmissions: distinct(field: data___transmission)
      years: distinct(field: data___year)
    }
    allPrismicModel {
      edges {
        node {
          prismicId
          data {
            name
            make {
              id
            }
          }
        }
      }
    }
    allPrismicMake {
      edges {
        node {
          data {
            name
          }
          prismicId
        }
      }
    }
  }
`;

export const VehiclesSearch = ({lang, slice}) => {
  let labels = slice.primary;
  const [vehicles, setVehicles] = useState([]);
  const fromYear = useRef();
  const toYear = useRef();
  const [makesExpanded, setMakesExpanded] = useState(false);
  const [selectedMake, setSelectedMake] = useState(null);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const searchVehicles = (currentPage = 0) => {
    const from = fromYear.current ? fromYear.current.value : 0;
    const to = toYear.current ? toYear.current.value : 100000;
    const types = Array.from(document.querySelectorAll(".vehicle-type"))
      .filter((input) => input.checked)
      .map((input) => input.name);
    const makes = Array.from(document.querySelectorAll(".vehicle-make"))
      .filter((input) => input.checked)
      .map((input) => input.value);
    const models = Array.from(document.querySelectorAll(".vehicle-model"))
      .filter((input) => input.checked)
      .map((input) => input.value);

    const Prismic = require("@prismicio/client").default;
    const client = Prismic.client("https://usatrt-ua.prismic.io/api");
    const query = [
      Prismic.Predicates.at("document.type", "vehicle"),
      Prismic.Predicates.gt("my.vehicle.year", parseInt(from, 10) - 1),
      Prismic.Predicates.lt("my.vehicle.year", parseInt(to, 10) + 1),
    ];
    if (makes.length) {
      query.push(Prismic.Predicates.any("my.vehicle.make", makes));
    }
    if (models.length) {
      query.push(Prismic.Predicates.any("my.vehicle.model", models));
    }
    if (types.length) {
      query.push(Prismic.Predicates.any("my.vehicle.type", types));
    }

    client.query(query, {pageSize: 20, page: currentPage+1}).then((response) => {
      setTotalPages(response.total_pages);
      setVehicles(response.results);
    });
  };

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      searchVehicles();
    }
  }, []);

  const onPageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
    searchVehicles(pageIndex);
  }

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        let {
          types,
          engines,
          years,
          transmissions,
          edges,
        } = data.allPrismicVehicle;

        const compareByName = (a, b) => {
          if(a.data.name > b.data.name) return 1;
          else if(a.data.name < b.data.name) return -1
          else return 0
        }

        const makes = data.allPrismicMake.edges.map(item => item.node).sort(compareByName);
        const models = data.allPrismicModel.edges.map(item => item.node).sort(compareByName);

        const _makes = !makesExpanded ? makes.slice(0, 5) : makes;
        const _models = selectedMake ? (models.filter(model => model.data.make.id === selectedMake)) : [];
        return (
          <section className="container padding-wrapper">
            <div className=" row flex-column-reverse flex-md-row g-5">
              <div className="col-12 col-md-7 col-xl-9">
                {vehicles && vehicles.length
                  ? vehicles.map((vehicle) => {
                    return (
                      <VehicleItem
                        key={vehicle.uid}
                        uid={vehicle.uid}
                        vehicle={vehicle.data.vehicle}
                        makes={makes}
                        models={models}
                        lang={lang}
                        labels={labels}
                      />
                    );
                  })
                  : edges.map((vehicle) => {
                    return (
                      <VehicleItem
                        key={vehicle.node.uid}
                        uid={vehicle.node.uid}
                        vehicle={vehicle.node.data}
                        lang={lang}
                        labels={labels}
                      />
                    );
                  })}
                <Pagination totalPages={totalPages} onChangePage={onPageChange} currentPage={currentPage}/>
              </div>
              <div className="col-12 col-md-5 col-xl-3">
                <StickyBox offsetTop={20} offsetBottom={20}>
                  <div className="p-3 p-lg-4 shadow">
                    <button
                      onClick={() => {
                        setCurrentPage(0)
                        searchVehicles(0)
                      }}
                      className="btn btn-md btn-info w-100 mb-4"
                    >
                      {labels.label_search}
                    </button>
                    <div className="item-search">
                      <div className="mb-3">
                        <div className="font-size-20 text-dark d-inline-block me-3">
                          {labels.label_year}
                        </div>
                        <a
                          data-bs-toggle="collapse"
                          href="#collapseYear"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseYear"
                        >
                          <img
                            className="icon-down"
                            alt="i"
                            src={IconDown}
                          />
                        </a>
                      </div>
                      <div
                        className="collapse show"
                        id="collapseYear"
                        data-bs-target="#ccollapseYear"
                        aria-controls="collapseYear"
                      >
                        <div className="border border-radius-25 d-inline-block py-2 px-3 me-3">
                          <div className="d-inline-block">
                            {labels.label_from}
                            <select ref={fromYear}>
                              {years
                                .sort()
                                .map((year, index) => {
                                    return (
                                      <option
                                        selected={index === 0}
                                        value={year}
                                        key={index}
                                      >
                                        {year}
                                      </option>
                                    );
                                  },
                                )}
                            </select>
                          </div>
                        </div>
                        <div className="border border-radius-25 d-inline-block py-2 px-3">
                          <div className="d-inline-block">
                            {labels.label_to}
                            <select ref={toYear}>
                              {years
                                .sort()
                                .map(
                                  (year, index) => {
                                    return (
                                      <option
                                        selected={index === years.length - 1}
                                        value={year}
                                        key={index}
                                      >
                                        {year}
                                      </option>
                                    );
                                  },
                                )}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item-search">
                      <div className="mb-3">
                        <div className="font-size-20 text-dark d-inline-block me-3">
                          {labels.label_car}
                        </div>
                        <a
                          data-bs-toggle="collapse"
                          href="#collapseAuto"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseAuto"
                        >
                          <img
                            className="icon-down"
                            alt="i"
                            src={IconDown}
                          />
                        </a>
                      </div>
                      <div
                        className="collapse show"
                        id="collapseAuto"
                        data-bs-target="#collapseAuto"
                        aria-controls="collapseAuto"
                      >
                        {types.map((type, index) => {
                          return (
                            <div className="form-check" key={index}>
                              <input
                                className="form-check-input vehicle-type"
                                name={type}
                                type="checkbox"
                                id={type}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={type}
                              >
                                {type}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="item-search">
                      <div className="mb-3">
                        <div className="font-size-20 text-dark d-inline-block me-3">
                          {labels.label_make}
                        </div>
                        <a
                          data-bs-toggle="collapse"
                          href="#collapseMark"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseMark"
                        >
                          <img
                            className="icon-down"
                            alt="i"
                            src={IconDown}
                          />
                        </a>
                      </div>
                      <div
                        className="collapse show"
                        id="collapseMark"
                        data-bs-target="#collapseMark"
                        aria-controls="collapseMark"
                        onChange={(event) => {
                          setSelectedMake(event.target.value);
                        }}
                      >
                        {_makes.map((make) => {
                          return (
                            <div
                              key={make.prismicId}
                              className="form-check"
                            >
                              <input
                                className="form-check-input vehicle-make"
                                name="make"
                                value={make.prismicId}
                                type="radio"
                                id={make.prismicId}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={make.prismicId}
                              >
                                {make.data.name}
                              </label>
                            </div>
                          );
                        })}
                        {!makesExpanded ? (
                          <button
                            className="btn btn-link"
                            onClick={() => {
                              setMakesExpanded(
                                !makesExpanded,
                              );
                            }}
                          >
                            <small>
                              {labels.label_view_all}
                            </small>
                          </button>
                        ) : null}
                      </div>
                    </div>
                    {selectedMake ? (
                      <div className="item-search">
                        <div className="mb-3">
                          <div className="font-size-20 text-dark d-inline-block me-3">
                            {labels.label_model}
                          </div>
                          <a
                            data-bs-toggle="collapse"
                            href="#collapseModel"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseModel"
                          >
                            <img
                              className="icon-down"
                              alt="i"
                              src={IconDown}
                            />
                          </a>
                        </div>
                        <div
                          className="collapse show"
                          id="collapseModel"
                          data-bs-target="#collapseModel"
                          aria-controls="collapseModel"
                        >
                          {_models.map((model) => {
                            return (
                              <div
                                key={model.prismicId}
                                className="form-check"
                              >
                                <input
                                  className="form-check-input vehicle-model"
                                  name={model.prismicId}
                                  value={model.prismicId}
                                  type="checkbox"
                                  id={model.prismicId}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={model.prismicId}
                                >
                                  {model.data.name}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </StickyBox>
              </div>
            </div>
          </section>
        );
      }}
    />
  );
};

import React from "react";
import { HiChevronDoubleLeft, HiChevronDoubleRight} from "react-icons/all";

export const Pagination = ({currentPage = 0, totalPages = 0, onChangePage = () => {}}) => {
  if(totalPages > 0) {
    return (
      <>
        <nav aria-label="mx-auto">
          <ul className="pagination">
            <li className="page-item me-3">
              <button className="btn btn-link page-link page-chevron" disabled={currentPage === 0} onClick={() => onChangePage(currentPage - 1)}>
                <HiChevronDoubleLeft />
              </button>
            </li>
            {Array(totalPages).fill(1).map((i, index) => {
              return (
                <li key={index} className={`page-item page-number ${currentPage === index ? 'active' : ''} mx-2`}><a className="page-link user-select-none" onClick={() => onChangePage(index)}>{index + 1}</a></li>
              )
            })}
            <li className="page-item ms-3">
              <button className="btn btn-link page-link page-chevron" disabled={currentPage === totalPages -1} onClick={() => onChangePage(currentPage + 1)}>
                <HiChevronDoubleRight />
              </button>
            </li>
          </ul>
        </nav>
      </>
    )
  }
  return null;
}

const {DEFAULT_LANG} = require("../../properties");
const languageCodes = {
  'ru' : 'Русский',
  'uk-uk' : 'Українська',
}

module.exports.getLangPrefix = lang => {
  return lang && lang !== DEFAULT_LANG ? `/${lang}` : "";
}

module.exports.getHTMLLang = lang => {
  return lang.split("-")[0];
}

module.exports.getLanguageByCode = langCode => {
  return languageCodes[langCode];
};

module.exports.getLanguageCodes = () => {
    return Object.keys(languageCodes);
}

module.exports.delocalizePath = (path) => {
    const langRegexGroup = `(${module.exports.getLanguageCodes().join('|')})`;
    const pattern = String.raw`^(https?:\/\/[\w.:]+)?(\/${langRegexGroup}(\/|$))`;
    return path.replace(pattern, '/');
}

module.exports.localizePath = (path, lang) => {
    path = module.exports.delocalizePath(path);
    if (lang && lang !== DEFAULT_LANG) {
        return `/${lang}${path}`;
    } else {
        return path;
    }
}

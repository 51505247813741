import React, {useState} from "react";
import {HeaderWithLineComponent} from "./header-with-line.component";
import EmployeeImg from "../images/employee.jpeg";
import {BsTelegram, FaViber, FaWhatsapp} from "react-icons/all";
import {GatsbyImage} from "gatsby-plugin-image";


/**
 * @param {PrismicPageDataBodyEmployees} slice
 * @return {JSX.Element}
 * @constructor
 */

export const Employees = ({slice}) => {
    const {primary, items} = slice;
    const groupedEmployees = {};
    const [team, setTeam] = useState(null);

    items.forEach(item => {
        if(!groupedEmployees[item.team]) {
            groupedEmployees[item.team] = [];
        }
        groupedEmployees[item.team].push(item);
    })
    return (
    <section className="container padding-wrapper">
        <HeaderWithLineComponent text={primary.title.text}/>
        <div className="text-center mb-3 mb-md-5 mt-5">
            {Object.keys(groupedEmployees).map(key => {
                return (
                    <button onClick={() => setTeam(key)} className="btn border-0 parallelogram gradient-blue w-280 py-2 mb-3 mx-4">
                        <div className="content text-center">
                            {key}
                        </div>
                    </button>
                )
            })}
        </div>
        <ul className="row">
            {(groupedEmployees[team]|| groupedEmployees[Object.keys(groupedEmployees)[0]]).map(
                /**
                 *
                 * @param {PrismicPageDataBodyEmployeesItem} employee
                 * @returns {JSX.Element}
                 */
                (employee) => {
                return (<li className="col-12 col-md-6 col-xl-4 mb-4 text-center">
                    <GatsbyImage className="employee-img mb-3" alt={employee.photo.alt} image={employee.photo.gatsbyImageData}/>
                    <div className="mb-3">
                        <h5>
                            {employee.name}
                        </h5>
                        {employee.post}
                    </div>
                    <small>
                        <a href={'tel://' + employee.telephone}>
                            {employee.telephone}
                        </a>
                    </small>

                    <div>
                        {employee.viber ? (<a className="mx-1" href={`viber://chat?number=${employee.telephone}`}>
                            <FaViber/>
                        </a>) : null}

                        {employee.telegram ? (<a className="mx-1" href={`https://t.me`}>
                            <BsTelegram/>
                        </a>) : null}

                        {employee.whatsapp ? (<a className="mx-1" href={`whatsapp://send?phone=${employee.telephone}`}>
                            <FaWhatsapp/>
                        </a>) : null}
                    </div>
                </li>)
            })}
        </ul>
    </section>
    )
}
